<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/leadtimebyCountryService'
import leadtimeCapacityContainerService from '../api/leadtimeCapacityContainerService'
export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getUsLeadtimeCapacityContainer() {
      const leadtimeCapacityContainerMeta = await leadtimeCapacityContainerService.getMetaWithValidation()
      helpers.processMetaDetails(
        leadtimeCapacityContainerMeta,
        'leadtimeCapacityContainer',
        'leadtimebyCountryId',
        'leadtimebyCountry',
        leadtimeCapacityContainerService
      )
      return leadtimeCapacityContainerMeta;
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getUsLeadtimeCapacityContainer()
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'app.pages')
      this.metadata = meta
    })
  }
}
</script>
